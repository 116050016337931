import React from "react";
import { TemplateDownloadUrl } from "./Constants";
import { QQLink } from "common/components/QQLink";
export enum ErrorType {
  Generic = 1,
  ShipTo,
  ParseNoHeader,
  ParseLength,
  ParseDbSave,
  ParseFileStorage,
  ParseNoItems,
  QuoteDownload,
  QuoteSave,
  NoUIDFound,
  ParseNoShape,
  ParseInvalidSize,
  ParseNoGrade,
  ParseNoQuantity,
  SequenceMissing,
  UploadLimitReached,
  UploadFormatInvalid,
  UploadMultiple,
}
export interface ErrorMessage {
  title: string;
  message: React.ReactNode;
}

export const ErrorMap = new Map<number, ErrorMessage>([
  [
    ErrorType.Generic,
    {
      title: "Service Error",
      message: "We encountered an internal error. Our service may be down, Please try again later.",
    },
  ],
  [ErrorType.ShipTo, { title: "Ship-To Error", message: "City, State not found." }],
  [
    ErrorType.ParseNoHeader,
    {
      title: "Uploaded File Error",
      message: (
        <>
          The file you have uploaded does not have a header row. Please try again or{" "}
          <QQLink href={TemplateDownloadUrl} download="export">
            download
          </QQLink>{" "}
          the template file.
        </>
      ),
    },
  ],
  [
    ErrorType.ParseLength,
    {
      title: "Uploaded File Error",
      message:
        "The file you have uploaded has lengths we do not recognize. Please review and try again.",
    },
  ],
  [
    ErrorType.ParseDbSave,
    {
      title: "Service Error",
      message: "Unable save items at this time. Our service may be down, Please try again later.",
    },
  ],
  [
    ErrorType.ParseFileStorage,
    {
      title: "Service Error",
      message:
        "Unable to retrieve items uploaded file from storage. Our service may be down, Please try again later.",
    },
  ],
  [
    ErrorType.ParseNoItems,
    {
      title: "Upload Error",
      message:
        "Unable to retrieve items from your uploaded file. Verify the file you have uploaded is not Empty.",
    },
  ],
  [
    ErrorType.QuoteDownload,
    {
      title: "Service Error",
      message:
        "Unable to download your quote at this time. Our service may be down, Please try again later.",
    },
  ],
  [
    ErrorType.QuoteSave,
    {
      title: "Service Error",
      message:
        "Unable to save your quote at this time. Our service may be down, Please try again later.",
    },
  ],
  [ErrorType.NoUIDFound, { title: "Uploaded File Error", message: "File was not found." }],
  [
    ErrorType.ParseNoShape,
    {
      title: "Uploaded File Error",
      message: <>The file is missing a Shape entry. Please review and try again.</>,
    },
  ],
  [
    ErrorType.ParseInvalidSize,
    {
      title: "Upload Failed",
      message: <>The file is missing a Size entry. Please review and try again.</>,
    },
  ],
  [
    ErrorType.ParseNoGrade,
    {
      title: "Upload Failed",
      message: <>The file is missing a Grade entry. Please review and try again.</>,
    },
  ],
  [
    ErrorType.ParseNoQuantity,
    {
      title: "Upload Failed",
      message: <>The file is missing a Quantity entry. Please review and try again.</>,
    },
  ],
  [
    ErrorType.SequenceMissing,
    {
      title: "Upload Failed",
      message: <>The file is missing a Sequence entry. Please review and try again.</>,
    },
  ],
  [
    ErrorType.UploadLimitReached,
    {
      title: "Upload Error",
      message:
        "The uploaded file contains more than 100 rows. Please split the file into smaller parts, each containing up to 100 rows, and upload them separately",
    },
  ],
  [
    ErrorType.UploadFormatInvalid,
    {
      title: "Upload Failed",
      message:
        "The file format you have selected is not currently supported. Please select an .XLS or .XLSX file to continue.",
    },
  ],
  [
    ErrorType.UploadMultiple,
    { title: "Upload Error", message: "Please select a single file to upload." },
  ],
]);

const errorManager =
  (log: typeof console.log) =>
  (error: Map<number, ErrorMessage>) =>
  (type: string, message?: React.ReactNode) => {
    let returnMessage: ErrorMessage = { title: "", message: null };
    let errorMessage = error.get(Number(type));
    if (errorMessage) {
      returnMessage.title = errorMessage.title;
      returnMessage.message = message ?? errorMessage.message;
    } else {
      //if not in our map then apply general error message
      log(`Error id: ${type} not found, using generic message`);

      errorMessage = type.includes("xlsx")
        ? error.get(ErrorType.UploadFormatInvalid)
        : error.get(ErrorType.Generic);
      returnMessage = errorMessage ?? returnMessage;
    }

    return returnMessage;
  };

export const getErrorMessage = errorManager(console.log)(ErrorMap);
